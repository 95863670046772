module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"VistaSocial","short_name":"VistaSocial","start_url":"/","background_color":"#FAFAFD","display":"minimal-ui","icon":"/codebuild/output/src1613702860/src/codestar-connections.us-east-1.amazonaws.com/git-http/582987825237/us-east-1/c4e3c329-6568-4176-89e4-6a510f650d63/webalgorithm/vistasocial-gatsby/src/static/logo/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"260922c400c4abf60121ad9890577549"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5XMQRJS","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
